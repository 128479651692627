const DialogBox = function(){
    this.build = function(txt, callback){
        const cont = createElement('div').addClass('dialog-box-cloud');
        const dialog =  cont.createElementInside('div').addClass('dialog-box');
        const message =  dialog.createElementInside('div').addClass('dialog-box-message').innerText = txt;
        const footer =  dialog.createElementInside('div').addClass('dialog-box-footer');
        const btYes  =  footer.createElementInside('a').addClass('btn').addClass('btn-primary');
        const btNo  =  footer.createElementInside('a').addClass('btn').addClass('btn-danger');
        btYes.innerText = 'Oui';
        btNo.innerText = 'Non';
        btNo.addEvent('click', function(e){cont.remove()});
        btYes.addEvent('click', function(e){callback(); cont.remove()});
        $('body').append(cont);
    }

    this.confirmForm = function(txt, form){
        this.build(txt, function(){ form.submit()});
        return false;
    }
}
window.dialogBox = new DialogBox();

